import React from 'react'
import { FaArrowCircleRight } from 'react-icons/fa'

export default [
  {
    id: '1',
    icon: <FaArrowCircleRight />,
    ParaPoint: '⟶',
    ParaOne: "You're not alone.",
    ParaTwo:
      "While these tools promised best-in-class features, they've created a bigger problem: fragmentation that's costing you real money.",
    ParaThree:
      "Fortunately, modern Procurement suites now offer what point solutions couldn't: true integration without the clunkiness of legacy platforms.",
    ParaFour:
      "Let's explore how Procurement Tech changed from suites to point solutions—and why smart teams are swinging back.",
    HeadingOne: "When Tools Multiply But Value Doesn't",
    ParaFive: "Count your Procurement tools. Go ahead—we'll wait.",
    ParaSix: `Most teams stop counting somewhere between "Too many" and "Wait, we have that one too?" A sourcing platform bought in 2021 that seemed essential. Last summer's contract system promised to fix everything. The supplier management tool no one quite mastered. Each purchase made sense in isolation. And surprise, surprise…the various point solutions aren’t integrated with each other, so welcome to the world of siloed data.`,
    ParaSeven: 'Each promised to solve one specific headache.',
    ParaEight: `Now, your team juggles passwords instead of managing spend. They copy-paste data between systems instead of analyzing it. Meanwhile, that "complete supplier view" sits scattered across five platforms, three spreadsheets, and one exhausted analyst's brain.`,
    ParaNine:
      'The math is simple: Every "best-in-class" point solution adds its own annual subscription fee, always an implementation fee with change orders, separate training requirements, and integration challenges. But the bigger cost comes from what falls between these systems—missed savings, duplicate efforts, and suppliers and internal stakeholders who are impacted due to how disconnected your view really is.',
    HeadingTwo: 'When Procurement Tech Was Young',
    ParaTen: `Remember your first ERP implementation? The one that promised to digitize everything but somehow left Procurement and AP teams printing POs and scanning invoices?`,
    ParaEleven:
      'The story starts there—with ERPs built for Finance that treated Procurement like an afterthought. Sure, they could handle basic purchasing. But strategic sourcing? Supplier risk management? Contract automation? Catalogs?  Not so much.',
    ParaTwelve: `Then came the first Procurement Suites promising to do everything your ERP couldn't. The price tag? Only slightly less than your annual indirect spend. The implementation timeline? Somewhere between "forever" and "never quite finished."`,
    ParaThirteen: `No wonder Procurement teams started looking elsewhere. When nimble point solutions appeared—each targeting one specific frustration, each promising quick deployment and instant ROI—they seemed like the perfect answer. Finally, tools built for Procurement teams, not Finance departments.`,
    ParaFourteen: `What nobody mentioned: These quick fixes would become five, then ten, then too many to track.`,
    HeadingThree: `“There's a Point Solution for That” (and that, and that, and that)`,
    ParaFifteen: `The year’s 2018, and you’re at yet another Procurement conference. Every vendor promised the same thing: quick implementation, instant ROI, and a user interface that "just works." No more waiting for IT. No more million-dollar price tags. No more year-long deployments.`,
    ParaSixteen: `The pitch worked. Teams ditched their clunky suites in droves. Why wrestle with one when you could get best-in-class everything? A sleek sourcing tool here. A modern contract platform there. Each one cheaper than a full suite. Each one solving one specific problem perfectly.`,
    ParaSeventeen: `Except "perfectly" had an expiration date.`,
    ParaEighteen: `That sourcing tool? It couldn't talk to your contract system. Those supplier profiles? Scattered across three platforms. Risk data? Living in yet another system. Suddenly, your Procurement team needed IT help anyway—just to make their "IT-free" solutions work together.`,
    ParaNineteen: `And suppliers? They sold the same software to your London office that Singapore already owned—at a higher price—because your disconnected systems couldn't show who had what or what anyone paid.`,
    HeadingFour: 'Counting the Real Cost of Point Solution Proliferation',
    ParaTwenty: `Start adding up your point solution subscriptions. Now add the hours your team spends copying data between systems. Don't forget the cost of maintaining each integration, training new hires on multiple platforms, and managing separate vendor relationships.`,
    ParaTwentyOne: `But the real expenses? They're hiding in plain sight.`,
    ParaTwentyTwo: `Take that global supplier charging different prices across regions. Multiply that by hundreds of suppliers exploiting the same gaps. Add the savings opportunities your team misses because they're too busy reconciling data to analyze it. Factor in the contracts auto-renewing because renewal dates live in a system that doesn’t proactively notify you.`,
    ParaTwentyThree: `Here's what your CFO sees: Individual point solutions cost less than a suite. Here's what they don't see: The money leaking through system gaps every month, the duplicate purchases across departments, and the suppliers profiting from your fragmented view.`,
    HeadingFive: `Modern Suites Finally Deliver`,
    ParaTwentyFour: `We know what you're thinking: "Didn't suites already fail us once?" Yes—but that was before a modern, AI-enabled from the start, and ‘easy-to-connect to other systems’ platform existed, and before Procurement teams had a seat at the tech table.`,
    ParaTwentyFive: `Today's Procurement Suites aren't just old ERPs or S2P Suites  with a fresh coat of paint. Built on platforms like Google Cloud, they offer what point solutions promised, but couldn't deliver:`,
    ParaTwentySix: `◉ True integration capability so you can connect all your data`,
    ParaTwentySeven: `◉ Real-time insights that come from your entire commitment ecosystem`,
    ParaTwentyEight: `◉ AI built directly into the platform fabric from ‘Day One’ and not retrofitted as a reaction to the latest market trends`,
    ParaTwentyNine: `The impact is immediate. When a modern suite replaces your point solutions, you're not just merging subscriptions. You're eliminating integration costs, reducing training needs, and giving your team back countless hours spent switching between systems and data gaps.`,
    ParaThirtyOne: `Teams find pricing variations they never knew existed. Suppliers can't hide between system gaps. And those auto-renewing contracts? They're finally visible before, not after, they renew.`,
    ParaThirtyTwo: `The best part? Implementation now takes weeks, not years. And that "easy-to-use" promise? It actually delivers.`,
    HeadingSix: 'Breaking Free from Point Solution Pain',
    ParaThirtyThree: `Ready to consolidate your tech stack? Here's what successful teams get right:`,
    ParaThirtyFour: `Start with your data. Where does it live now? What actually needs to migrate? Most teams find they're paying for features they rarely use. Don't port over complexity you don't need.`,
    ParaThirtyFive: `Look for quick wins. Begin with the systems causing the most pain—usually contract management or supplier data. When teams see better results from less effort, adoption follows naturally.`,
    ParaThirtySix: `Time your switch strategically. Contract renewal season is perfect for consolidation. You're already reviewing costs, and suppliers expect changes. Plus, you might catch those auto-renewals before they slip through.`,
    ParaOneLeft: '',
    ParaOneAnchor: 'Raindrop',
    ParaOneRight:
      "was born from this exact challenge. Built by Procurement Veterans who lived through the ERP, S2P and Point Solution eras, our platform tackles real-world consolidation challenges and tracks your commitments. We didn't just build another Procurement Suite—we built the solution we wished we had when managing Procurement.",
    ShiftUrlParaOne: 'https://raindrop.com/whyraindrop',
    ParaThirtySeven: `The result? A platform that connects every part of your Procurement process. Your contracts talk to your sourcing events. Your supplier profiles sync with your risk data. Your spend analytics pull from every transaction. All built on Google Cloud Platform, where AI doesn't just automate tasks—it drives real savings, brings efficiencies, and adds value across your entire source-to-pay cycle.`,
    ParaThirtyEight: `Because Procurement works better when everything works together.`,
    HeadingEight: 'The Suite Spot Between Quick Start and Complete Coverage',
    ParaThirtyNine: `The pendulum swing from suites to point solutions taught Procurement teams a valuable lesson: The answer isn't "one tool that does everything" or "best-in-class tools for everything." It's having everything work together seamlessly.`,
    ParaThirtyTen: `Modern Procurement Suites, like Raindrop built on the Google Cloud Platform, deliver exactly that. No more choosing between ease of use and enterprise power. No more trading off quick deployment for deep functionality. No more gaps for suppliers to exploit.`,
    ParaThirtyEleven: `But timing matters. With Procurement taking center stage in business resilience, the cost of disconnected systems keeps rising. Teams who move first gain more than efficiency—they get a competitive edge in supplier negotiations, risk management, and strategic planning.`,
    ParaThirtyTwelve: `The future of Procurement tech isn't about having more tools. It's about having the right ones, working together, delivering actual value. The pendulum has swung back to suites, but this time, it's different. This time, it's on your terms.`,
    ParaTwoLeft: 'Ready to simplify your tech stack?',
    ParaTwoAnchor: `Let's talk`,
    ParaTwoRight: `about what's possible.`,
    ShiftUrlParaTwo: 'https://share.hsforms.com/15ZhMdunrTji6Z5_wWjrDyA4702y',
  },
]
