import React from 'react'
import styled from 'styled-components'
import { ReUsePtag } from './ReUsePtag'
import { ReUseHTwotag } from './ReUseHTwoTag'
import PointSolutionPendulumRestData from '../Data/PointSolutionPendulumRestData'
import { ReUsePtagwithAnchorTag } from './ReUsePtagwithAnchorTag'

const AppPointSolutionPendulumRestWapper = styled.div`
  max-width: 1920px;
  margin: auto;
  .PointSolutionPendulumContentSectionEmail {
    padding: 5px;
    @media (max-width: 1090px) {
      padding: 15px;
    }
  }
  .PointSolutionPendulumHeadingEmail {
    max-width: 1150px;
    padding: 0px 15px 15px 45px;
    @media (max-width: 1090px) {
      padding: unset;
      max-width: unset;
    }
  }
  .PointSolutionPendulumLeadParaStyleOne {
    max-width: 1150px;
    padding: 0px 15px 15px 45px;
    @media (max-width: 1090px) {
      padding: unset;
      max-width: unset;
    }
  }
  .PointSolutionPendulumLeadParaStyle {
    max-width: 1150px;
    padding: 15px 15px 15px 45px;

    @media (max-width: 1090px) {
      padding: unset;
      max-width: unset;
    }
  }
  .achorPara {
    color: #34a1d5;
    font-size: 20px;
    font-weight: 700;
    padding-right: 8px;
    padding-left: 8px;
    text-decoration: none;
  }
  .achorPara:hover {
    color: #047cc2;
    text-decoration: underline;
  }
  .iconColor {
    color: #34a1d5;
    padding: 15px 15px 15px 0px;
  }
  .PointSolutionPendulumParaSubPointStyle {
    color: #047cc2;
    max-width: 1150px;
    padding: 15px 15px 15px 45px;
    font-size: 22px;
    font-weight: bold;
    @media (max-width: 1090px) {
      padding: unset;
      max-width: unset;
    }
  }
  .ItalyPara {
    display: flex;
    color: #071741;
    max-width: 1150px;
    font-size: 1.2rem;
    line-height: 1.4;
    padding: 0px 15px 15px 45px;
    @media (max-width: 1090px) {
      padding: unset;
      max-width: unset;
      font-size: 16px;
    }
  }
  .BlogsimageOne {
    width: 90%;
    display: flex;
    padding-left: 24px;
    /* float: right; */
    @media (max-width: 1090px) {
      float: unset;
      width: 100%;
    }
  }
  .RetailLeadParaStyleOne {
    padding: 0px 15px 0px 45px;
  }
  .PointSolutionPendulumHeadingFiveEmail {
    padding: 15px 15px 15px 45px;
  }
  .ParaStyleMultiple {
    padding: 0px 15px 0px 45px;
  }
`

export const AppPointSolutionPendulumRest = () => {
  return (
    <AppPointSolutionPendulumRestWapper>
      <div>
        {PointSolutionPendulumRestData.map((data, index) => {
          return (
            <div className="MainDigitizationNeedsSection" key={index}>
              <div className="PointSolutionPendulumContentSectionEmail">
                <ReUsePtag para={data.ParaOne} paraStyle="PointSolutionPendulumLeadParaStyleOne" />
                <ReUsePtag para={data.ParaTwo} paraStyle="PointSolutionPendulumLeadParaStyleOne" />
                <ReUsePtag para={data.ParaThree} paraStyle="PointSolutionPendulumLeadParaStyleOne" />
                <ReUsePtag para={data.ParaFour} paraStyle="PointSolutionPendulumLeadParaStyleOne" />
                <ReUseHTwotag Heading={data.HeadingOne} HeadingStyle="PointSolutionPendulumHeadingEmail" />
                <ReUsePtag para={data.ParaFive} paraStyle="PointSolutionPendulumLeadParaStyleOne" />
                <ReUsePtag para={data.ParaSix} paraStyle="PointSolutionPendulumLeadParaStyleOne" />
                <ReUsePtag para={data.ParaSeven} paraStyle="PointSolutionPendulumLeadParaStyleOne" />
                <ReUsePtag para={data.ParaEight} paraStyle="PointSolutionPendulumLeadParaStyleOne" />
                <ReUsePtag para={data.ParaNine} paraStyle="PointSolutionPendulumLeadParaStyleOne" />
                <ReUseHTwotag Heading={data.HeadingTwo} HeadingStyle="PointSolutionPendulumHeadingEmail" />
                <ReUsePtag para={data.ParaTen} paraStyle="PointSolutionPendulumLeadParaStyleOne" />
                <ReUsePtag para={data.ParaEleven} paraStyle="PointSolutionPendulumLeadParaStyleOne" />
                <ReUsePtag para={data.ParaTwelve} paraStyle="PointSolutionPendulumLeadParaStyleOne" />
                <ReUsePtag para={data.ParaThirteen} paraStyle="PointSolutionPendulumLeadParaStyleOne" />
                <ReUsePtag para={data.ParaFourteen} paraStyle="PointSolutionPendulumLeadParaStyleOne" />
                <ReUseHTwotag Heading={data.HeadingThree} HeadingStyle="PointSolutionPendulumHeadingEmail" />
                <ReUsePtag para={data.ParaFifteen} paraStyle="PointSolutionPendulumLeadParaStyleOne" />
                <ReUsePtag para={data.ParaSixteen} paraStyle="PointSolutionPendulumLeadParaStyleOne" />
                <ReUsePtag para={data.ParaSeventeen} paraStyle="PointSolutionPendulumLeadParaStyleOne" />
                <ReUsePtag para={data.ParaEighteen} paraStyle="PointSolutionPendulumLeadParaStyleOne" />
                <ReUsePtag para={data.ParaNineteen} paraStyle="ParaStyleMultiple" />
                <ReUseHTwotag Heading={data.HeadingFour} HeadingStyle="PointSolutionPendulumHeadingFiveEmail" />
                <ReUsePtag para={data.ParaTwenty} paraStyle="ParaStyleMultiple" />
                <ReUsePtag para={data.ParaTwentyOne} paraStyle="ParaStyleMultiple" />
                <ReUsePtag para={data.ParaTwentyTwo} paraStyle="ParaStyleMultiple" />
                <ReUsePtag para={data.ParaTwentyThree} paraStyle="PointSolutionPendulumLeadParaStyle" />
                <ReUseHTwotag Heading={data.HeadingFive} HeadingStyle="PointSolutionPendulumHeadingFiveEmail" />
                <ReUsePtag para={data.ParaTwentyFour} paraStyle="ParaStyleMultiple" />
                <ReUsePtag para={data.ParaTwentyFive} paraStyle="ParaStyleMultiple" />
                <ReUsePtag para={data.ParaTwentySix} paraStyle="ParaStyleMultiple" />
                <ReUsePtag para={data.ParaTwentySeven} paraStyle="ParaStyleMultiple" />
                <ReUsePtag para={data.ParaTwentyEight} paraStyle="ParaStyleMultiple" />
                <ReUsePtag para={data.ParaTwentyNine} paraStyle="ParaStyleMultiple" />
                <ReUsePtag para={data.ParaThirtyOne} paraStyle="ParaStyleMultiple" />
                <ReUsePtag para={data.ParaThirtyTwo} paraStyle="ParaStyleMultiple" />
                <ReUseHTwotag Heading={data.HeadingSix} HeadingStyle="PointSolutionPendulumHeadingFiveEmail" />
                <ReUsePtag para={data.ParaThirtyThree} paraStyle="ParaStyleMultiple" />
                <ReUsePtag para={data.ParaThirtyFour} paraStyle="ParaStyleMultiple" />
                <ReUsePtag para={data.ParaThirtyFive} paraStyle="ParaStyleMultiple" />
                <ReUsePtag para={data.ParaThirtySix} paraStyle="ParaStyleMultiple" />
                <ReUsePtagwithAnchorTag
                  ParaLeft={data.ParaOneLeft}
                  anchorPara={data.ParaOneAnchor}
                  ParaRight={data.ParaOneRight}
                  href={data.ShiftUrlParaOne}
                  target="_blank"
                  rel="noopener noreferrer"
                  anchorStyle="achorPara"
                  paraStyle="RetailLeadParaStyleOne"
                />
                <ReUsePtag para={data.ParaThirtySeven} paraStyle="ParaStyleMultiple" />
                <ReUsePtag para={data.ParaThirtyEight} paraStyle="ParaStyleMultiple" />
                <ReUseHTwotag Heading={data.HeadingEight} HeadingStyle="PointSolutionPendulumHeadingFiveEmail" />
                <ReUsePtag para={data.ParaThirtyNine} paraStyle="ParaStyleMultiple" />
                <ReUsePtag para={data.ParaThirtyTen} paraStyle="ParaStyleMultiple" />
                <ReUsePtag para={data.ParaThirtyEleven} paraStyle="ParaStyleMultiple" />
                <ReUsePtag para={data.ParaThirtyTwelve} paraStyle="ParaStyleMultiple" />
                <ReUsePtagwithAnchorTag
                  ParaLeft={data.ParaTwoLeft}
                  anchorPara={data.ParaTwoAnchor}
                  ParaRight={data.ParaTwoRight}
                  href={data.ShiftUrlParaTwo}
                  target="_blank"
                  rel="noopener noreferrer"
                  anchorStyle="achorPara"
                  paraStyle="RetailLeadParaStyleOne"
                />
              </div>
            </div>
          )
        })}
      </div>
    </AppPointSolutionPendulumRestWapper>
  )
}
