import React from 'react'
import styled from 'styled-components'
import { AppSideNavEmailName } from './AppSideNavEmailName'
import { AppPointSolutionPendulumRest } from './AppPointSolutionPendulumRest'

const AppPointSolutionPendulumWithNavWapper = styled.div`
  max-width: 1920px;
  margin: auto;
  .PointSolutionPendulumSectionSection {
    display: flex;
    justify-content: space-between;
    padding: 50px;
    @media (max-width: 1090px) {
      flex-wrap: wrap;
      padding: 15px;
    }
  }
  .PointSolutionPendulumSectionNav {
    order: 0;
    @media (max-width: 1090px) {
      order: 1;
      margin: auto;
    }
  }
  .PointSolutionPendulumSectionContent {
    order: 1;
    @media (max-width: 1090px) {
      order: 0;
    }
  }
`

export const AppPointSolutionPendulumWithNav = () => {
  return (
    <AppPointSolutionPendulumWithNavWapper>
      <div className="PointSolutionPendulumSectionSection">
        <div className="PointSolutionPendulumSectionNav">
          <AppSideNavEmailName />
        </div>
        <div className="PointSolutionPendulumSectionContent">
          <AppPointSolutionPendulumRest />
        </div>
      </div>
    </AppPointSolutionPendulumWithNavWapper>
  )
}
